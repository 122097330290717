<template>
  <b-row>
    <b-col>
      <h3 class="text-white">Vorschau:</h3>
      <NewsSingle :news="form" :key="newsKey"/>
    </b-col>
    <b-col>
      <b-form v-if="form" @submit.stop.prevent="" class="text-white" style="font-size: 14px">
        <label class="mt-2">Kategorie:</label>
        <br>
        <b-form-select v-model="newsCategorySelect.selected" :options="newsCategorySelect.options"
                       @change="updateNewsCategory"/>
        <br>
        <label class="mt-2">Priorität:</label>
        <br>
        <b-form-select v-model="newsPrioritySelect.selected" :options="newsPrioritySelect.options"
                       @change="updateNewsPriority"/>
        <br>
        <label class="mt-2">Überschrift:</label>
        <b-form-input v-model="form.headline" placeholder="Überschrift..."/>
        <label class="mt-2">Nachricht:</label>
        <b-form-textarea v-model="form.message" style="min-height: 100px" debounce="500"/>
      </b-form>
      <b-button :variant="variantSuccess" class="mt-3" @click="addNews">Abschicken</b-button>
      <div class="mt-3">
        <b-link :href="markdownLink" target="_blank">Markdown - Erklärung</b-link>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import {getRequest, putRequest} from "@/modules/requests";
import NewsSingle from "@/components/news/NewsSingle";
import {mapGetters} from "vuex";

export default {
  name: "NewsAdd",
  components: {
    NewsSingle
  },
  data() {
    return {
      newsCategorySelect: {
        selected: null,
        options: []
      },
      newsPrioritySelect: {
        selected: null,
        options: []
      },
      form: {
        headline: '',
        news_category: null,
        news_priority_id: 1,
        message: '',
        created_at: dayjs()
      },
      newsKey: 0,
      markdownLink: 'https://experienceleague.adobe.com/docs/contributor/contributor-guide/writing-essentials/markdown.html?lang=de'
    }
  },
  watch: {
    form: {
      handler() {
        this.newsKey += 1
      },
      deep: true
    }
  },
  methods: {
    updateNewsPriority() {
      this.form.news_priority_id = this.newsPrioritySelect.selected
    },
    updateNewsCategory() {
      this.form.news_category = { displayname: _.find(this.newsCategorySelect.options, (option) => {
          if(option.value === this.newsCategorySelect.selected) { return true; }
        }).text }
    },
    async getNewsCategories() {
      this.newsCategorySelect.options = []
      await getRequest('news/news-categories', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.newsCategorySelect.options.push({value: element.id, text: element.displayname})
            })
          })
    },
    async getNewsPriorities() {
      this.newsPrioritySelect.options = []
      await getRequest('news/news-priorities', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.newsPrioritySelect.options.push({value: element.id, text: element.displayname})
            })
          })
    },
    async addNews() {
      let data = {
        news_category_id: this.newsCategorySelect.selected,
        news_priority_id: this.newsPrioritySelect.selected,
        headline: this.form.headline,
        message: this.form.message,
      }
      await putRequest('news/add', data, this, 'News wurde erfolgreich erstellt.', 'News konnte nicht erstellt werden.')
          .then(() => {
            this.form = {
              headline: '',
              news_category: null,
              news_priority_id: 1,
              message: '',
              created_at: dayjs()
            }
            this.newsCategorySelect.selected = null
            this.newsPrioritySelect.selected = null
            this.$emit('refresh')
          })
    },
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  },
  mounted() {
    this.getNewsCategories();
    this.getNewsPriorities();
  }
}
</script>

<style lang="scss" scoped>
</style>
